import { LOCALES } from '../locales'

const traductions = {
 [LOCALES.FRENCH]: {
   "home_title": "RETOURNER OU ÉCHANGER VOTRE COMMANDE",
   "home_title_2": "CONDITIONS D'ÉCHANGE ET RETOUR",
   "home_title_non_eu": "RETOURNER VOTRE COMMANDE",
   "home_title_2_non_eu": "CONDITIONS DE RETOUR",
   "home_1": "Si vous avez acheté votre produit sur notre site, vous disposez de 14 jours à compter de la date de livraison pour faire un retour ou un échange sur notre plateforme dédiée. En France métropolitaine et en Belgique, les retours sont à notre charge. Et en cas d'échange, les frais de la nouvelle livraison ne vous seront pas facturés !",
   "home_2": "Un produit retourné ne sera accepté que s'il a été acheté sur notre site et est dans son emballage d'origine.",
   "home_3": "La procédure de retour peut prendre un peu de temps (entre 2 et 3 semaines).",
   "home_5": "Dès que votre retour sera validé, vous serez notifié.e par email et vous recevrez votre échange ou remboursement sous 10 jours ouvrés.",
   "home_4": "Et pour ",
   "home_4_1": "les Parisiens & Petite couronne",
   "home_4_2": ", notre service de coursier est à votre disposition pour faire vos retours sur rendez-vous au bureau ou à la maison.",
   "login": "Connexion",
   "phone": "Numéro de téléphone",
   "email_phone": "Email / Téléphone",
   "order_number": "Numéro de commande",
   "order": "Commande",
   "login_comment": "* Si vous faites un second échange pour une même commande, ajoutez RR devant votre numéro de commande.",
   "submit": "Valider",
   "logout": "Accueil",
   "return": "Retour",
   "cancel": "Annuler",
   "next": "Suivant",
   "return_login": "Retour à la page de connexion",
   "select_order": "Sélectionnez une commande",
   "required": "Champs requis",
   "loading": "Chargement",
   "invalid_email": "Adresse email invalide",
   "email_input": "Veuillez renseigner votre adresse email pour recevoir votre message de confirmation",
   "already_generated_returns": "Vous avez déjà généré vos documents de retour",
   "download_returns": "Télécharger mes documents de retour",
   "request_received": "Confirmation de votre échange / retour",
   "request_received_info_1": "Merci, nous avons bien pris en compte votre demande d'échange ou de retour !",
   "request_received_info_2": "Vous allez recevoir d’ici quelques minutes un mail avec votre étiquette de retour ainsi que le document à glisser dans votre colis.",
   "request_received_info_3": "Une fois votre colis renvoyé, il faut compter 2 à 3 semaines pour qu'il soit traité. Vous recevrez une notification par email une fois qu'il sera validé.",
   "request_received_info_4": "Concernant le remboursement, le délai varie en fonction des banques, au maximum, vous devriez le recevoir sous 10 jours ouvrés après avoir reçu la notification par email ci-dessus.",
   
   "request_received_non_eu": "Confirmation de votre retour",
   "request_received_info_1_non_eu": "Merci, nous avons bien pris en compte votre demande de retour !",
   "request_received_info_2_non_eu": "Vous allez recevoir d’ici quelques minutes un mail avec le document à glisser dans votre colis. Nous vous rappelons que le coût de transport est à votre charge. Nous vous conseillons de passer par Fedex ou DHL pour avoir un courrier suivi.",
   "request_received_info_3_non_eu": "Une fois votre colis renvoyé, il faut compter 2 à 3 semaines pour qu'il soit traité. Vous recevrez une notification par email une fois qu'il sera validé.",
   "request_received_info_4_non_eu": "Concernant le remboursement, le délai varie en fonction des banques, au maximum, vous devriez le recevoir sous 10 jours ouvrés après avoir reçu la notification par email ci-dessus.",

   "booking_courier_error": "Erreur lors de votre prise de rendez-vous coursier",
   "booking_courier_confirm": "Votre confirmation de rendez-vous avec le coursier vient de vous être envoyée par e-mail",
   "booking_courier_issues": "Nous rencontrons quelques difficultés pour prendre rendez-vous avec votre coursier, nous vous invitons à réessayer dans quelques instants. Si le problème persiste, n'hésitez pas à nous envoyer un email à sav@sympl.fr",
   "try_again": "Réessayer",
   "return_place": "À quelle adresse souhaitez-vous recevoir votre échange",
   "address": "Adresse",
   "city": "Ville",
   "postal_code": "Code postal",
   "country": "Pays",
   "item_exchange_by": "Votre produit va être échangé par",
   "item_returned": "Votre produit va être retourné",
   "item_not_received": "Vous n'avez pas reçu le produit",
   "select_items": "SÉlectionnez le ou les articles que vous souhaitez Échanger ou renvoyer.",
   "select_items_non_eu": "SÉlectionnez le ou les articles à renvoyer",
   "confirm_return": "Valider ma demande de retour",
   "book_courier": "Réserver un coursier",
   "exchange_reason": "Quelle est la raison de votre demande d'échange ?",
   "return_reason": "Quelle est la raison de votre demande de retour ?",
   "selected_reason": "Raison sélectionnée :",
   "too_big": "Trop grand",
   "too_small": "Trop petit",
   "color_is_not_suitable": "La couleur ne convient pas",
   "cut_is_not_suitable": "La coupe ne convient pas",
   "material_is_not_suitable": "La matière ne convient pas",
   "ordered_two_different_sizes": "J’ai commandé 2 tailles différentes",
   "ordered_two_different_colors": "J'ai commandé 2 couleurs différentes",
   "damaged_item": "Article endommagé",
   "changed_mind": "J’ai trouvé un produit similaire entre temps",
   "too_late": "Le produit est arrivé trop tard dans la saison",
   "add_comment": "Souhaitez-vous ajouter un commentaire ?",
   "exchange_item": "Quel article souhaitez-vous en échange ?",
   "available_15days": "Peut-être disponible sous 15 jours",
   "exchange": "Échange",
   "exchange_not_possible": "Il est impossible d'échanger ce produit",
   "refund": "Remboursement",
   "wrong_item": "Mauvais article livré",
   "no_order_to_return": "Vous ne possédez aucune commande retournable",
   "order_not_found": "Commande introuvable",
   "order_cannot_be_returned": "Il semble que votre commande ne puisse pas être retournée",
   "country_not_available": "Impossible de générer une étiquette retour de pour une commande expédié dans ce pays",
   "problem_creating_return": "Il semblerait qu'il y ait eu un souci lors de la génération de votre étiquette retour. Veuillez réessayer dans quelques secondes",
   "from_which_address": "Depuis quelle adresse souhaitez-vous retourner vos articles ?",
   "confirm_cancel_courier": "Êtes-vous sûr de vouloir annuler votre réservation de coursier ?",
   "courier_cancelled": "Votre demande de coursier a bien été annulée",
   "courier_cancelled_error": "Erreur lors de l'annulation de votre bon de retour",
   "send_error_1": "Envoyez-nous un email à",
   "send_error_2": ", en précisant le code d'erreur",
   "send_error_3": "et l'id",
   "carrier_choice": "Choisissez votre transporteur",
   "pickup_on_map": "Afficher sur une carte",
   // Haut 
   "col": "Col",
    "epaules": "Epaules",
    "poitrine": "Poitrine",
    "aisselles": "Aisselles",
    "ventre": "Ventre",
    "longueur_manches": "Longueur des manches",
    "largueur_manches": "Largueur des manches",
    // Bas
    "taille" : "Taille",
    "fourche" : "Fourche",
    "hanches" : "Hanches",
    "cuisses" : "Cuisses",
    "mollets" : "Mollets",
    "chevilles" : "Chevilles",
    // Chaussures
    "talon" :"Talon",
    "orteils" :"Orteils",
    "longueur_totale" : "Longueur Totale",
    "globalement_trop_large" : "Globalement trop large",
    "globalement_trop_serre" : "Globalement trop serré",
    "footer_contact":"N'hésitez pas à nous contacter si vous avez des questions, à l’adresse",
    "already_courier": "Votre demande de coursier a bien été annulée",
    "info_courier_1": "Il va se présenter le ",
    "and": " et ",
    "between": " entre ",
    "at_this_address": " à l'adresse suivante :",
    "error": "Erreur",
    "error_page_1":"Cette page est temporairement indisponible",
    "error_page_2":"Pas de panique nos agents sont sur le coup",
    "not_found":"PAGE NON TROUVÉE",
    "not_found_1":"Cette page n'est pas disponible",
    "no_courier_available": "Plus de coursier disponible",
    "eligible_for_courier": "Bonne nouvelle votre adresse est éligible à un échange ou un retour par coursier",
    "not_eligible_for_courier": "Il n'y a malheureusement plus de créneaux disponibles pour un échange ou un retour par coursier, vous pouvez tout de même faire votre retour par voie postale.",
    "poste_office":"Voie Postale",
    "courier":"Coursier",
    "confirm_completed":"Êtes-vous sûr d'avoir terminé ?",
    "info_before_complete":"Une fois validé il vous sera impossible de faire une nouvelle modification sur votre commande",
    "generation_error": "Erreur lors de la génération de votre bon de retour",
    "return_label_sent_email":"Votre étiquette de retour vient de vous être envoyée par e-mail",
    "difficult_return_label_generation":"Notre transporteur rencontre quelques difficultés pour la génération de votre étiquette retour, nous vous invitons à réessayer dans quelques instants. Si le problème persiste, n'hésitez pas à nous envoyer un email à sav@sympl.fr",
    "email":"Email",
    "lastname":"Nom",
    "firstname":"Prénom",
    "choose_time_delivery":"Choisissez l'heure et le jour du rendez-vous",
    "request_return_label":"Générer mon étiquette de retour",
    "request_return_label_non_eu":"Générer mon formulaire de retour",
    "book_courier":"Réserver un coursier sympl",
    "comment":"Commentaire",
    "return_item":"Retourner",
    "unknow_error":"Une erreur inconnue s'est produite",
    "out_of_stock":"Rupture de stock",
    "footer_faq":"Besoin d’aide ? Plus de détails",
    "here":"ici",
    "home_3":"Pour les commandes à l’étranger ou d’outre-mer, la procédure est la même mais le retour sera à vos frais.",
    "langFR":"Français",
    "langEN":"Anglais",
    "langDE":"Allemand",
    "return_info_eu_1": "Si vous avez acheté votre produit sur notre site, vous disposez de 14 jours à compter de la date de livraison pour faire un retour ou un échange sur notre plateforme dédiée. En France métropolitaine, en Belgique et au Luxembourg les retours sont à notre charge. Et en cas d'échange, les frais de la nouvelle livraison ne vous seront pas facturés !",
    "return_info_eu_2": "Un produit retourné ne sera accepté que s'il est dans son emballage d'origine, intact et non utilisé.",
    "return_info_eu_3": "La procédure de retour peut prendre un peu de temps (entre 2 et 3 semaines). Dès que votre retour sera validé, vous serez notifié.e par email et vous recevrez votre échange ou remboursement sous 10 jours ouvrés.",
    "return_info_non_eu_1": "Si vous avez acheté un produit sur notre site, vous disposez de 14 jours à compter de la date de livraison pour faire un retour sur notre plateforme dédiée (le coût de transport est à votre charge).",
    "return_info_non_eu_2": "Nous vous conseillons de passer par Fedex ou DHL pour avoir un courrier suivi. Nous n'acceptons que les retours, les échanges ne sont disponibles que pour les commandes passées depuis la France métropolitaine, Luxembourg et la Belgique.",
    "return_info_non_eu_3": "Un produit retourné ne sera accepté que s'il est dans son emballage d'origine, intact et non utilisé.",
    "return_info_non_eu_4": "La procédure de retour peut prendre un peu de temps (entre 2 et 3 semaines). Dès que votre retour sera validé, vous serez notifié.e par email et vous recevrez votre remboursement sous 10 jours ouvrés.",
    "select_country": "Sélectionnez votre pays",
    "home_special_UK": "Pour obtenir les conditions de retours pour le Royaume-Uni, veuillez vous rendre",
    "here":"ici",
    "home_special_UK_link":"https://www.labonnebrosse.co.uk/pages/uk-refund-policy"
 }
}

export default traductions