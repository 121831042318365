import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import translate from '../../../i18n/translate';
/**
* @description Page de Confirmation pour les envois postaux
*/
class carrierConfirmation extends Component {
  render() {
    const { loadingCreateReturn, successCreateReturn, shipFromCountry } = this.props;

    // Determine if country is EU
    const isEUCountry = ['FR', 'BE', 'LU'].includes(shipFromCountry);

    return (
      <div>
        {
          this.props.loadingCreateReturn ?
          <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                style={{marginTop: '11%'}}>
            <CircularProgress id='circle-loader'/>
          </Grid>
          :
          <div>
            <DialogTitle style={{textAlign: 'center'}}>
              {
                this.props.successCreateReturn ?
                <span id='success-create-return-label'>
                  {translate("request_received")}
                </span>
                :
                <span id="error-create-return-label">
                  {translate("generation_error")}
                </span>
              }
            </DialogTitle>
            <DialogContent style={{textAlign: 'center'}}>
              <DialogContentText id="alert-dialog-slide-description">
              {
                this.props.successCreateReturn ?
                 <div>
                  <p>{translate(isEUCountry ? "request_received_info_1" : "request_received_info_1_non_eu")}</p>
                  <p>{translate(isEUCountry ? "request_received_info_2" : "request_received_info_2_non_eu")}</p>
                  <p>{translate(isEUCountry ? "request_received_info_3" : "request_received_info_3_non_eu")}</p>
                  <p>{translate(isEUCountry ? "request_received_info_4" : "request_received_info_4_non_eu")}</p>
                 </div>
                :
                <span>
                  {translate("difficult_return_label_generation")}
                </span>
              }
              </DialogContentText>
            </DialogContent>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    successCreateReturn: state.returnItem.successCreateReturn,
    loadingCreateReturn: state.returnItem.loadingCreateReturn,
    shipmentType: state.returnAdress.shipmentType,
    shipFromCountry: state.returnAdress.countryCode
  }
}

export default connect(mapStateToProps)(carrierConfirmation)
