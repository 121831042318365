export const returnReasonCode = [
  {
    name: 'Article défectueux',
    name_fr: 'Article défectueux',
    name_gb: 'Damaged item',
    name_de: 'Beschädigtes Artikel',
    code: 'damaged_item'
  }/*,
  {
    name: 'Le modèle ne convient pas',
    name_fr: 'Le modèle ne convient pas',
    name_gb: 'The model is not suitable',
    name_de: 'Das Modell passt nicht',
    code: 'model_is_not_suitable'
  }*/
]
