import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';

import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ReactFlagsSelect from 'react-flags-select'

import Login from './component/auth/Login'
import PrivateRoute from './component/auth/PrivateRoute'
import ReturnAdress from './component/returnAdress/ReturnAdress'
import ReturnItems from './component/returnItems/home/ReturnItems'
import AlreadyGenerateReturnLabel from './component/alreadyGenerateReturnLabel/AlreadyGenerateReturnLabel'
import AlreadyRequestCourier from './component/alreadyRequestCourier/AlreadyRequestCourier'
import notFound from './component/errorPage/notFound'
import internalError from './component/errorPage/internalError'
import logo from './logo.png'
import SnackbarError from './component/snackbarError/SnackbarError'
import ErrorBoundary from './ErrorBoundary'
import mainImg from './img/main-img-lbb.png'

import Provider from './i18n/Provider'
import { LOCALES } from './i18n/locales'
import translate from './i18n/translate';


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Header = styled.header`
  background-color: #ffffff;
  box-shadow: 0px 1px 7px rgba(0,0,0,.5);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

const LogoLink = styled.a`
  height: 40px;
  @media (max-width: 768px) {
    height: 28px;
  }
`

const Logo = styled.img`
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const MainContent = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  @media (max-width: 768px) {
    display: block;
  }
`

const LeftColumn = styled.div`
  flex: 1;
  padding: 100px;
  overflow-y: auto;
  align-items: center;
  align-content: center;

  @media (max-width: 768px) {
    flex: 1 0 100%;
    padding:10px;
  }
`


const RightColumn = styled.div`
  flex: 1;
  background-image: url(${mainImg});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    display: none;
  }
`

const StyledButton = styled(Button)`
  && {
    border: 1px solid #000;
    margin-left: 10px;
    text-transform: none;
  }
`

const LanguageButton = styled(Button)`
  && {
    border: 1px solid #000;
    margin-right: 10px;
    text-transform: none;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      margin-right: 0px;
      margin-left: 3px;
    }
  }
`

const styles = theme => ({
  logoutIcon: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    color: '#000',
    fontSize: 20,
    marginLeft: 10,
    cursor: 'pointer'
  },
  logoutButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    borderRadius: 0
  },
});

function GlobeIcon(className) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 256 256"
      className={className}
    >
      <path
        fill="currentColor"
        d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24m88 104a87.6 87.6 0 0 1-3.33 24h-38.51a157.4 157.4 0 0 0 0-48h38.51a87.6 87.6 0 0 1 3.33 24m-114 40h52a115.1 115.1 0 0 1-26 45a115.3 115.3 0 0 1-26-45m-3.9-16a140.8 140.8 0 0 1 0-48h59.88a140.8 140.8 0 0 1 0 48ZM40 128a87.6 87.6 0 0 1 3.33-24h38.51a157.4 157.4 0 0 0 0 48H43.33A87.6 87.6 0 0 1 40 128m114-40h-52a115.1 115.1 0 0 1 26-45a115.3 115.3 0 0 1 26 45m52.33 0h-35.62a135.3 135.3 0 0 0-22.3-45.6A88.29 88.29 0 0 1 206.37 88Zm-98.74-45.6A135.3 135.3 0 0 0 85.29 88H49.63a88.29 88.29 0 0 1 57.96-45.6M49.63 168h35.66a135.3 135.3 0 0 0 22.3 45.6A88.29 88.29 0 0 1 49.63 168m98.78 45.6a135.3 135.3 0 0 0 22.3-45.6h35.66a88.29 88.29 0 0 1-57.96 45.6"
      />
    </svg>
  );
}

class App extends Component {
  state = {
    isAuthenticated: false,
    locale: LOCALES.FRENCH
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get('lang');
    
    if (langParam) {
      const localeMap = {
        'EN': 'GB',  // EN is already mapped to GB flag
        'FR': 'FR',
        'DE': 'DE'
      };
      
      const upperLangParam = langParam.toUpperCase();
      if (localeMap[upperLangParam]) {
        this.onSelectFlag(localeMap[upperLangParam]);
      }
    }
  }

  handleAuthentificated = () => {
    this.setState({ isAuthenticated: true })
  }

  onSelectFlag = (countryCode) => {
    const locales = {
      FR: LOCALES.FRENCH,
      GB: LOCALES.ENGLISH,
      DE: LOCALES.DEUTCH
    }
    this.setState({ locale: locales[countryCode] || LOCALES.FRENCH })
    document.documentElement.lang = countryCode.toLowerCase();
  }

  render() {
    const { isAuthenticated, locale } = this.state;
    const { classes } = this.props


    return (
      <Provider locale={locale}>
        <ErrorBoundary>
          <AppContainer>
            <SnackbarError />
            <Header>
              <LogoLink href="https://cair-paris.com" target="_blank" rel="noopener noreferrer">
                <Logo src={logo} alt="logo cair" />
              </LogoLink>
              <ButtonContainer>
                <LanguageButton variant="outlined" color="primary" style={{ maxHeight: '36.5px' }}>
                  <GlobeIcon className="mr-2" />
                  {/* Language Switcher Component */}
                  <ReactFlagsSelect
                    placeholder={translate("lang"+locale.slice(0,2).toUpperCase())}
                    countries={["GB", "FR", "DE"]}
                    customLabels={{ "GB": "EN", "FR": "FR", "DE": "DE" }}
                    onSelect={this.onSelectFlag}
                    selectedSize={14}
                    selectButtonClassName="menu-flags-button"
                    style={{ border: 'none', boxShadow: 'none', textTransform: 'uppercase', padding: '0px!important' }}
                  />
                </LanguageButton>
                {isAuthenticated && (
                  <>
                    <StyledButton variant="outlined" color="primary" onClick={() => window.location.reload()}>
                      {translate("logout")}
                    </StyledButton>
                  </>
                )}
              </ButtonContainer>
            </Header>
            <MainContent>
              <LeftColumn>
                <Switch>
                  <Route exact path="/" render={props => <Login handleAuthentificated={this.handleAuthentificated} />} />
                  <PrivateRoute exact path="/internal-error" component={internalError} />
                  <PrivateRoute path="/already-generate-return-label" component={AlreadyGenerateReturnLabel} isAuthenticated={isAuthenticated} />
                  <PrivateRoute path="/already-request-courier" component={AlreadyRequestCourier} isAuthenticated={isAuthenticated} />
                  <PrivateRoute path="/return-adress" component={ReturnAdress} isAuthenticated={isAuthenticated} />
                  <PrivateRoute path="/return-items" component={ReturnItems} isAuthenticated={isAuthenticated} />
                  <Route component={notFound} />
                </Switch>
              </LeftColumn>
              <RightColumn />
            </MainContent>
          </AppContainer>
        </ErrorBoundary>
      </Provider>
    )
  }
}

export default withStyles(styles)(App)
