import React, { Component } from 'react'
import { Redirect, withRouter } from "react-router-dom"
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'

import { setSelectedCountry } from '../returnAdress/actions';
import countryList from '../returnAdress/countryList.json';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

import { resetSwipeableIndex } from './actions'

import AuthStep from './authStep/AuthStep'
import SelectCommandStep from './selectedCommandStep/SelectCommandStep'
import translate from '../../i18n/translate'


const ConnexionBackground = styled(Paper)`
  background-color: #f0f0f0;
  padding: 0px;
`

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    text-align: center;
  }
`

const Paragraph = styled.p`
  font-size: 14px;
  text-align: left;
  max-width: 450px;
  margin-bottom: 30px;
`

const DownloadAppLink = styled.a`
  margin: 60px 15px 0px 15px;
`

/**
 * Composant de la page de login
 */

class Login extends Component {
  state = {
    redirectToReferrer: false,
    selectedCountry: 'FR'
  };

  componentDidMount() {
    this.props.resetSwipeableIndex();
      
    // Add automatic language/country selection
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get('lang');
    
    if (langParam) {
      const upperLangParam = langParam.toUpperCase();
      if (upperLangParam === 'EN') {
        this.props.setSelectedCountry('US');
      } else if (upperLangParam === 'FR') {
        this.props.setSelectedCountry('FR');
      } else if (upperLangParam === 'DE') {
        this.props.setSelectedCountry('DE');
      }
    }
  }

  handleCountryChange = (event) => {
    this.props.setSelectedCountry(event.target.value);
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } }
    const { redirectToReferrer, selectedCountry } = this.props

    if (redirectToReferrer) {
      return <Redirect to={from} />
    }

    const isUKCountry = selectedCountry === 'GB';
    const isEUCountry = selectedCountry === 'FR' || selectedCountry === 'BE' || selectedCountry === 'LU';
    const title = isUKCountry ? translate("home_special_UK") + "<a href='https://www.labonnebrosse.co.uk/pages/uk-refund-policy'>" + translate("here")+ "</a>" : (isEUCountry ? translate("home_title") : translate("home_title_non_eu"));
    const subtitle = isUKCountry ? '' : (isEUCountry ? translate("home_title_2") : translate("home_title_2_non_eu"));

    return (
      <div>
        <LoginContainer>
          {isUKCountry && <Title>{translate("home_special_UK")} <a style={{color: '#000'}} href='https://www.labonnebrosse.co.uk/pages/uk-refund-policy'>{translate("here")}</a></Title>}
          {!isUKCountry && <Title>{title}</Title>}
          <div>
            <Paragraph>
              {!isUKCountry && <h1 style={{ fontSize: "14px", textAlign: "left", marginBottom: "30px" }}>{subtitle}</h1>}
              <FormControl variant="outlined" fullWidth style={{ marginTop: '0px', whiteSpace: 'pre-line' }}>
                <InputLabel style={{ transform: 'translate(0px, -6px) scale(0.75)' }} id="country-select-label">{translate("select_country")}</InputLabel>
                <Select
                  style={{ color: '#000' }}
                  labelId="country-select-label"
                  value={selectedCountry}
                  onChange={this.handleCountryChange}
                  label={translate("select_country")}
                >
                  {countryList.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!isUKCountry && (
                <>
                  <p style={{ color: '#000' }}>{translate(isEUCountry ? "return_info_eu_1" : "return_info_non_eu_1")}</p>
                  <p style={{ color: '#000' }}>{translate(isEUCountry ? "return_info_eu_2" : "return_info_non_eu_2")}</p>
                  <p style={{ color: '#000' }}>{translate(isEUCountry ? "return_info_eu_3" : "return_info_non_eu_3")}</p>
                  {!isEUCountry && <p style={{ color: '#000' }}>{translate("return_info_non_eu_4")}</p>}
                </>
              )}
            </Paragraph>
          </div>
          {!isUKCountry && (
            <div style={{width: '100%'}}>
              <ConnexionBackground elevation={0}>
                <SwipeableViews index={this.props.swipeableIndex} disabled>
                  <AuthStep handleAuthentificated={this.props.handleAuthentificated} />
                  <SelectCommandStep handleAuthentificated={this.props.handleAuthentificated} />
                </SwipeableViews>
              </ConnexionBackground>
            </div>
          )}
          <div>
            <Paragraph>
              {translate("footer_faq")} <b><a style={{ color: '#000', textDecoration: 'none' }} href="https://help.labonnebrosse.com/fr-FR/articles/echange-retour-et-remboursement-85759">{translate("here")}</a></b>.
              <br /><br />
              <span>{translate("footer_contact")} <b><a style={{ color: '#000', textDecoration: 'none' }} href="mailto:contact@cair-paris.com">contact@cair-paris.com</a></b></span>
            </Paragraph>
          </div>
        </LoginContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  swipeableIndex: state.login.swipeableIndex,
  selectedCountry: state.returnAdress.selectedCountry,
})

const mapDispatchToProps = (dispatch) => ({
  resetSwipeableIndex: () => dispatch(resetSwipeableIndex()),
  setSelectedCountry: (countryCode) => dispatch(setSelectedCountry(countryCode)), // Added dispatcher
})

Login = reduxForm({
  form: 'login',
})(Login)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));