import { LOCALES } from '../locales'

const traductions = {
    [LOCALES.DEUTCH]: {
        "home_title": "IHRE BESTELLUNG ZURÜCKGEBEN ODER UMTAUSCHEN",
        "home_title_2": "RÜCKSENDUNGS- UND UMTAUSCHBEDINGUNGEN",
        "home_title_non_eu": "IHRE BESTELLUNG ZURÜCKGEBEN",
        "home_title_2_non_eu": "RÜCKSENDUNGSBEDINGUNGEN",
        "home_1": "Rücksendungen und Umtausch sind innerhalb von 14 Tagen nach Erhalt des Pakets möglich, und die Rücksendungen sind in Frankreich kostenlos.",
        "home_2": "Bitte geben Sie unten Ihre E-Mail-Adresse oder Telefonnummer sowie Ihre Bestellnummer ein.",
        "home_3": "Artikel können nur in demselben Zustand zurückgegeben werden, in dem sie geliefert wurden. Das bedeutet, dass die Artikel keine Gebrauchsspuren aufweisen, nicht beschädigt, nicht verändert und nicht gewaschen sein dürfen. Seien Sie vorsichtig beim Anprobieren. Bitte senden Sie die Artikel in der Originalverpackung mit dem Rücksendeschein, allen Etiketten und der Kraftpapierverpackung zurück. Wenn Sie gut mit den Produkten umgehen, können wir sie an andere weitergeben, was Abfall vermeidet und alle zufriedenstellt.",
        "home_4": " ",
        "home_4_1": " ",
        "home_4_2": " ",
        "home_5": "Sobald Ihr Rücksendung bestätigt ist, werden Sie per E-Mail benachrichtigt und erhalten Ihren Umtausch oder Rückerstattung innerhalb von 10 Arbeitstagen.",
        "login": "Deine Kontaktdaten",
        "phone": "Telefonnummer",
        "email_phone": "E-Mail/Telefon",
        "order_number": "Bestellnummer",
        "order": "Bestellung",
        "login_comment": "*Wenn du Artikel aus derselben Bestellung ein zweites Mal zurücksendest, schreibe bitte RR vor die Bestellnummer.",
        "submit": "Bestätigen",
        "logout": "Startseite",
        "return": "Zurück",
        "cancel": "Abbrechen",
        "next": "Als Nächstes",
        "return_login": "Zurück zum Login",
        "select_order": "Wähle eine Bestellung aus",
        "required": "Pflichtfeld",
        "loading": "Lädt",
        "invalid_email": "Ungültige E-Mail Adresse",
        "email_input": "Gib deine E-Mail-Adresse ein, um eine Bestätigungsnachricht zu erhalten",
        "already_generated_returns": "Du hast deine Rücksendeunterlagen bereits angefordert",
        "download_returns": "Rücksendeunterlagen herunterladen",
        
        "request_received": "Bestätigung Ihres Umtauschs / Ihrer Rücksendung",
        "request_received_info_1": "Vielen Dank, wir haben Ihre Umtausch- oder Rücksendenanfrage erhalten!",
        "request_received_info_2": "In wenigen Minuten erhalten Sie eine E-Mail mit Ihrem Rücksendeetikett und dem Dokument, das Sie Ihrem Paket beilegen müssen.",
        "request_received_info_3": "Sobald Ihr Paket zurückgeschickt wurde, dauert die Bearbeitung 2-3 Wochen. Sie erhalten eine E-Mail-Benachrichtigung, sobald es bestätigt wurde.",
        "request_received_info_4": "Die Rückerstattung erfolgt je nach Bank unterschiedlich schnell, spätestens aber innerhalb von 10 Werktagen nach Erhalt der oben genannten E-Mail-Benachrichtigung.",
        
        "request_received_non_eu": "Bestätigung Ihrer Rücksendung",
        "request_received_info_1_non_eu": "Vielen Dank, wir haben Ihre Rücksendenanfrage erhalten!",
        "request_received_info_2_non_eu": "In wenigen Minuten erhalten Sie eine E-Mail mit dem Dokument, das Sie Ihrem Paket beilegen müssen. Bitte beachten Sie, dass die Versandkosten von Ihnen getragen werden müssen. Wir empfehlen Ihnen, Fedex oder DHL zu nutzen, um eine Sendungsverfolgung zu haben.",
        "request_received_info_3_non_eu": "Sobald Ihr Paket zurückgeschickt wurde, dauert die Bearbeitung 2-3 Wochen. Sie erhalten eine E-Mail-Benachrichtigung, sobald es bestätigt wurde.",
        "request_received_info_4_non_eu": "Die Rückerstattung erfolgt je nach Bank unterschiedlich schnell, spätestens aber innerhalb von 10 Werktagen nach Erhalt der oben genannten E-Mail-Benachrichtigung.",
        "booking_courier_error": "Es gab einen Fehler bei der Buchung deiner Rücksendung",
        "booking_courier_confirm": "Du hast soeben eine Bestätigung für deine Rücksendung erhalten",
        "booking_courier_issues": "Wir haben derzeit Probleme mit der Buchung deiner Rücksendung. Bitte versuche es in ein paar Minuten noch einmal. Sollte das Problem weiterhin bestehen, schick uns bitte eine E-Mail an support@asphalte-paris.com",
        "try_again": "Nochmal versuchen",
        "return_place": "Wohin sollen wir deine umgetauschten Artikel schicken?",
        "address": "Adresse",
        "city": "Stadt",
        "postal_code": "Postleitzahl",
        "country": "Land",
        "item_exchange_by": "Dein Artikel wird umgetauscht mit",
        "item_returned": "Dein Artikel wird zurückgeschickt",
        "item_not_received": "Du hast deine Sendung nicht erhalten",
        "select_items": "Wähle die Artikel deiner Bestellung aus, die du zurückgeben möchtest.",
        "select_items_non_eu": "Wähle die Artikel deiner Bestellung aus, die du zurückgeben möchtest.",
        "confirm_return": "Rückgabe bestätigen",
        "book_courier": "Rücksendung buchen",
        "exchange_reason": "Was ist der Grund für den Umtausch?",
        "return_reason": "Was ist der Grund für die Rücksendung?",
        "selected_reason": "Ausgewählter Grund:",
        "too_big": "Zu groß",
        "too_small": "Zu klein",
        "color_is_not_suitable": "Die Farbe gefällt mir nicht",
        "cut_is_not_suitable": "Die Passform ist nichts für mich",
        "material_is_not_suitable": "Das Material gefällt mir nicht",
        "ordered_two_different_sizes": "Ich habe 2 verschiedene Größen bestellt",
        "ordered_two_different_colors": "Ich habe 2 verschiedene Farben bestellt",
        "damaged_item": "Der Artikel ist beschädigt",
        "changed_mind": "In der Zwischenzeit habe ich ein ähnliches Produkt gefunden",
        "too_late": "Das Produkt kam zu spät in der Saison",
        "add_comment": "Möchtest du einen Kommentar hinzufügen?",
        "exchange_item": "Gegen welchen Artikel möchtest du diesen umtauschen?",
        "available_15days": "Innerhalb von 15 Tagen verfügbar",
        "exchange": "Umtausch",
        "exchange_not_possible": "Du kannst diesen Artikel nicht umtauschen",
        "refund": "Rückzahlung",
        "wrong_item": "Mir wurde der falsche Artikel geliefert",
        "no_order_to_return": "Auf deinem Konto befindet sich momentan keine Bestellung, die zurückgegeben werden kann",
        "order_not_found": "Die Bestellung wurde nicht gefunden",
        "order_cannot_be_returned": "Es sieht so aus, als ob deine Bestellung nicht zurückgegeben werden kann",
        "country_not_available": "Für eine Bestellung aus diesem Land kann kein Rücksendeetikett erstellt werden",
        "problem_creating_return": "Es scheint ein Problem bei der Erstellung des Rücksendeetiketts zu geben, bitte versuche es in ein paar Sekunden erneut",
        "from_which_address": "Von welcher Adresse aus möchtest du deine Artikel zurückschicken?",
        "confirm_cancel_courier": "Bist du sicher, dass du die Rücksendung stornieren möchtest?",
        "courier_cancelled": "Deine Rücksendung wurde storniert",
        "courier_cancelled_error": "Fehler beim Stornieren deines Rücksendeetiketts",
        "send_error_1": "Bitte kontaktiere uns unter",
        "send_error_2": "mit Verweis auf den Fehlercode",
        "send_error_3": "und die ID",
        "carrier_choice": "Wählen Sie Ihr Transportunternehmen",
        "pickup_on_map": "Auf einer Karte anzeigen",
        // Haut 
        "col": "Kragen",
        "epaules": "Schultern",
        "poitrine": "Brust",
        "aisselles": "Achseln",
        "ventre": "Bauch",
        "longueur_manches": "Ärmellänge",
        "largueur_manches": "Weite der Ärmel",
        // Bas
        "taille": "Taille",
        "fourche": "Hintern",
        "hanches": "Hüften",
        "cuisses": "Oberschenkel",
        "mollets": "Waden",
        "chevilles": "Knöchel",
        // Chaussures
        "talon": "Ferse",
        "orteils": "Zehen",
        "longueur_totale": "Gesamtlänge",
        "globalement_trop_large": "Insgesamt zu locker",
        "globalement_trop_serre": "Insgesamt zu eng",
        "footer_contact":"Zögern Sie nicht, uns zu kontaktieren, wenn Sie Fragen haben, unter ",
        "already_courier": "Ihre Kurieranfrage wurde erfolgreich storniert",
        "info_courier_1": "Er wird sich am ",
        "and": " und ",
        "between": " zwischen ",
        "at_this_address": " an folgender Adresse vorstellen:",
        "error": "Fehler",
        "error_page_1": "Diese Seite ist vorübergehend nicht verfügbar",
        "error_page_2": "Keine Panik, unsere Agenten sind dran",
        "not_found": "SEITE NICHT GEFUNDEN",
        "not_found_1": "Diese Seite ist nicht verfügbar",
        "no_courier_available": "Keine Kuriere mehr verfügbar",
        "eligible_for_courier": "Gute Nachrichten, Ihre Adresse ist für einen Austausch oder eine Rückgabe per Kurier geeignet",
        "not_eligible_for_courier": "Leider sind keine Termine mehr für einen Austausch oder eine Rückgabe per Kurier verfügbar, Sie können Ihre Rücksendung jedoch per Post vornehmen.",
        "poste_office": "Postweg",
        "courier": "Kurier",
        "request_return_label":"Rücksendeetikett anfordern",
        "request_return_label_non_eu":"Rückgabebestätigung anfordern",
        "return_item":"Artikel zurücksenden",
        "footer_faq": "Brauchen Sie Hilfe? Mehr Details",
        "here": "hier",
        "home_3": "Für Bestellungen im Ausland oder Übersee ist das Verfahren dasselbe, aber die Rücksendung erfolgt auf Ihre Kosten.",
        "langFR":"Französisch",
        "langEN":"Englisch",
        "langDE":"Deutsch",
        "return_info_eu_1": "Wenn Sie Ihr Produkt auf unserer Website gekauft haben, haben Sie ab dem Lieferdatum 14 Tage Zeit, um eine Rücksendung oder einen Umtausch über unsere dafür vorgesehene Plattform vorzunehmen. In Frankreich, Belgien und Luxemburg übernehmen wir die Rücksendekosten. Und im Falle eines Umtauschs werden Ihnen die neuen Versandkosten nicht berechnet!",
        "return_info_eu_2": "Eine Rücksendung wird nur akzeptiert, wenn sich das Produkt in der Originalverpackung befindet und unbenutzt ist.",
        "return_info_eu_3": "Der Rücksendeprozess kann etwas Zeit in Anspruch nehmen (zwischen 2 und 3 Wochen). Sobald Ihre Rücksendung bestätigt wurde, werden Sie per E-Mail benachrichtigt und erhalten Ihren Umtausch oder Ihre Rückerstattung innerhalb von 10 Werktagen.",
        "return_info_non_eu_1": "Wenn Sie ein Produkt auf unserer Website gekauft haben, haben Sie ab dem Lieferdatum 14 Tage Zeit, um eine Rücksendung über unsere dafür vorgesehene Plattform vorzunehmen (die Transportkosten tragen Sie selbst).",
        "return_info_non_eu_2": "Wir empfehlen Ihnen, Fedex oder DHL für eine nachverfolgbare Sendung zu nutzen. Wir akzeptieren nur Rücksendungen, Umtausch ist nur für Bestellungen aus Frankreich, Luxemburg und Belgien möglich.",
        "return_info_non_eu_3": "Eine Rücksendung wird nur akzeptiert, wenn sich das Produkt in der Originalverpackung befindet und unbenutzt ist.",
        "return_info_non_eu_4": "Der Rücksendeprozess kann etwas Zeit in Anspruch nehmen (zwischen 2 und 3 Wochen). Sobald Ihre Rücksendung bestätigt wurde, werden Sie per E-Mail benachrichtigt und erhalten Ihre Rückerstattung innerhalb von 10 Werktagen.",
        "home_special_UK": "Für die Rücksendungsbedingungen für Großbritannien, bitte folgen Sie",
        "here":"diesem Link",
        "home_special_UK_link": "https://www.labonnebrosse.co.uk/pages/uk-refund-policy"
    }
}

export default traductions