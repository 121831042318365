import { LOCALES } from '../locales'

const traductions = {
  [LOCALES.ENGLISH]: {
    "home_title": "RETURN OR EXCHANGE YOUR ORDER",
    "home_title_2": "RETURN AND EXCHANGE CONDITIONS",
    "home_title_non_eu": "RETURN YOUR ORDER",
    "home_title_2_non_eu": "RETURN CONDITIONS",
    "home_1": "You can return or exchange your order within 14 days of receiving the package, with free returns in France.",
    "home_2": "Please provide your email or phone number along with your order number below.",
    "home_3": "Items must be returned in the same condition as received, showing no signs of wear, damage, or alteration. Ensure items are sent back in their original packaging, including the return slip, labels, and kraft wrapping. By taking care of these items, we can pass them on to others, reducing waste and ensuring satisfaction for everyone.",
    "home_4": " ",
    "home_4_1": " ",
    "home_4_2": " ",
    "home_5": "As soon as your return is validated, you will be notified by email and you will receive your exchange or refund within 10 working days.",
    "login": "Fill in your details",
    "phone": "Telephone number",
    "email_phone": "Email/Telephone",
    "order_number": "Order number",
    "order": "Order",
    "login_comment": "*If you're returning things for a second time for the same order please add RR in front of the order number.",
    "submit": "Confirm",
    "logout": "Home",
    "return": "Return",
    "cancel": "Cancel",
    "next": "Next",
    "return_login": "Return to login",
    "select_order": "Select an order",
    "required": "Required field",
    "loading": "Loading",
    "invalid_email": "Invalid email address",
    "email_input": "Please enter your email address to receive your confirmation message",
    "already_generated_returns": "You have already raised your return documents",
    "download_returns": "Download my return documents",
    
    "request_received": "Exchange / Return Confirmation",
    "request_received_info_1": "Thank you, we have received your exchange or return request!",
    "request_received_info_2": "You will receive an email within a few minutes with your return label and the document to include in your package.",
    "request_received_info_3": "Once your package is sent back, please allow 2-3 weeks for processing. You will receive an email notification once it has been validated.",
    "request_received_info_4": "Regarding the refund, processing time varies by bank but you should receive it within 10 business days after receiving the email notification mentioned above.",
    
    "request_received_non_eu": "Return Confirmation",
    "request_received_info_1_non_eu": "Thank you, we have received your return request!",
    "request_received_info_2_non_eu": "You will receive an email within a few minutes with the document to include in your package. Please note that shipping costs are at your expense. We recommend using FedEx or DHL for tracked shipping.",
    "request_received_info_3_non_eu": "Once your package is sent back, please allow 2-3 weeks for processing. You will receive an email notification once it has been validated.",
    "request_received_info_4_non_eu": "Regarding the refund, processing time varies by bank but you should receive it within 10 business days after receiving the email notification mentioned above.",
    "booking_courier_error": "There was an error during the booking of your courier",
    "booking_courier_confirm": "You have just been sent a confirmation for your courier booking",
    "booking_courier_issues": "We are currently experiencing some issues with your courier booking. We would kindly ask you to try again in a few minutes. If the problem persists, please send us an email at support@asphalte-paris.com",
    "try_again": "Try again",
    "return_place": "Where would you like us to send your exchange?",
    "address": "Address",
    "city": "City",
    "postal_code": "Postal code",
    "country": "Country",
    "item_exchange_by": "Your item will be exchanged by",
    "item_returned": "Your item will be returned",
    "item_not_received": "You have not received your item",
    "select_items": "Select the items from your order that you wish to return.",
    "select_items_non_eu": "Select the items from your order that you wish to return.",
    "confirm_return": "Confirm my return",
    "book_courier": "Book a courier",
    "exchange_reason": "What is the reason for your exchange?",
    "return_reason": "What is the reason for your return?",
    "selected_reason": "Selected reason:",
    "too_big": "Too big",
    "too_small": "Too small",
    "color_is_not_suitable": "The colour is not right for me",
    "cut_is_not_suitable": "The fit is not right for me",
    "material_is_not_suitable": "The material is not right for me",
    "ordered_two_different_sizes": "I ordered 2 different sizes",
    "ordered_two_different_colors": "I ordered 2 different colours",
    "damaged_item": "The item is damaged",
    "changed_mind": "I've found a similar product in the meantime",
    "too_late": "The product arrived too late in the season",
    "add_comment": "Would you like to add a comment?",
    "exchange_item": "Which item would you like to exchange this for?",
    "available_15days": "May be available within 15 days",
    "exchange": "Exchange",
    "exchange_not_possible": "It is not possible to exchange this item",
    "refund": "Refund",
    "wrong_item": "Wrong item received",
    "no_order_to_return": "There is currently no order on your account that is eligible for return",
    "order_not_found": "Order not found",
    "order_cannot_be_returned": "It appears that your order cannot be returned",
    "country_not_available": "It is not possible to create a return label for an order from this country",
    "problem_creating_return": "There appears to be a problem creating a return label, please try again in a few seconds",
    "from_which_address": "From which address would you like to return your items?",
    "confirm_cancel_courier": "Are you sure that you want to cancel your courier booking?",
    "courier_cancelled": "Your courier booking has been cancelled",
    "courier_cancelled_error": "Error cancelling your returns label",
    "send_error_1": "Please contact us at",
    "send_error_2": "referencing the error code",
    "send_error_3": "and the id",
    "carrier_choice": "Select your transporter",
    "pickup_on_map": "Show on a map",
    // Haut 
    "col": "Collar",
    "epaules": "Shoulders",
    "poitrine": "Chest",
    "aisselles": "Armpits",
    "ventre": "Belly",
    "longueur_manches": "Sleeve length",
    "largueur_manches": "Sleeve width",
    // Bas
    "taille": "Waist",
    "fourche": "Buttocks",
    "hanches": "Hips",
    "cuisses": "Thighs",
    "mollets": "Calves",
    "chevilles": "Ankles",
    // Chaussures
    "talon": "Heel",
    "orteils": "Toes",
    "longueur_totale": "Total length",
    "globalement_trop_large": "Globally too loose",
    "globalement_trop_serre": "Globally too tight",
    "footer_contact":"Do not hesitate to contact us if you have any questions, at",
    "already_courier": "Your courier request has been successfully cancelled",
    "info_courier_1": "They will present themselves on ",
    "and": " and ",
    "between": " between ",
    "at_this_address": " at the following address:",
    "error": "Error",
    "error_page_1": "This page is temporarily unavailable",
    "error_page_2": "Don't panic, our agents are on it",
    "not_found": "PAGE NOT FOUND",
    "not_found_1": "This page is not available",
    "no_courier_available": "No more couriers available",
    "eligible_for_courier": "Good news, your address is eligible for an exchange or return by courier",
    "not_eligible_for_courier": "Unfortunately, there are no more slots available for an exchange or return by courier, you can still make your return by mail.",
    "poste_office": "Postal Service",
    "courier": "Courier",
    "request_return_label":"Generate my return label",
    "request_return_label_non_eu":"Generate my return form",
    "return_item":"Return item",
    "footer_faq": "Need help? More details",
    "here": "here",
    "home_3": "For orders abroad or overseas, the procedure is the same but the return will be at your expense.",
    "langFR":"French",
    "langEN":"English",
    "langDE":"German",
    "return_info_eu_1": "If you purchased your product on our site, you have 14 days from the delivery date to make a return or exchange on our dedicated platform. Returns are free of charge in mainland France and Belgium. And in case of exchange, the new delivery costs will not be charged!",
    "return_info_eu_2": "A returned product will only be accepted if it was purchased on our site and is in its original packaging.",
    "return_info_eu_3": "The return procedure can take some time (between 2 and 3 weeks).",
    "return_info_eu_4": "As soon as your return is validated, you will be notified by email and you will receive your exchange or refund within 10 business days.",
    "return_info_eu_5": "A returned product will only be accepted if it is in its original packaging, intact and unused. If the item is refused by Cair, it will be sent back to the Customer at Cair's expense without the Customer being able to claim any compensation.",
    "return_info_non_eu_1": "If you purchased your product on our site, you have 14 days from the delivery date to make a return on our dedicated platform (the transport cost is your responsibility). We recommend using Fedex or DHL for a tracked delivery. Returns are only available for orders placed from mainland France, Luxembourg and Belgium.",
    "return_info_non_eu_2": "A returned product will only be accepted if it has been purchased on our site and is in its original packaging. The return procedure can take a little time (between 2 and 3 weeks). Each brush is checked by hand and each order is processed, which allows a personalized return.",
    "return_info_non_eu_3": "You just have to enter your email or phone number used, your order number, and select the country of order below.",
    "return_info_non_eu_4": "The return procedure can take a little time (between 2 and 3 weeks). As soon as your return is validated, you will be notified by email and you will receive your refund within 10 business days.",
    "select_country": "Select your country",
    "home_special_UK": "For UK return policy, please follow",
    "here":"this link",
    "home_special_UK_link": "https://www.labonnebrosse.co.uk/pages/uk-refund-policy"
  }
}

export default traductions