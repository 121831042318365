import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { handleChangeSwipeableIndex, setStepsEnum, setReturnNatureCode, setDesiredItem } from '../../../actions';
import { stepsExchange, stepsReturn, stepsIncorectItem } from './stepsEnum';
import styled from 'styled-components'
import translate from '../../../../../i18n/translate';
let NoSwichableProductAvailable = styled.div`
  width: 60%;
  text-align: left;
  margin-top: 40px;
  border: 1px black solid;
  padding: 3px;
  margin-top: 40px;
  border-radius: 4px;
`

const MainStep = ({ 
  selectedItem, 
  handleChangeSwipeableIndex, 
  setStepsEnum, 
  setReturnNatureCode, 
  loadingSwitchableProducts, 
  switchableProducts, 
  setDesiredItem,
  selectedCountry
}) => {
  const renderButtonGroup = () => {
    if (loadingSwitchableProducts) {
      return (
        <Grid container justify="center" alignItems="center" direction='column' style={{marginTop: '10%'}}>
          <CircularProgress />
        </Grid>
      )
    } else {
      return (
        <Grid container justify="center" alignItems="center" direction='column'>
         {["FR", "BE", "LU"].includes(selectedCountry) && switchableProducts.length > 0 ? (
            <Button 
              id="exchange-button"
              type="submit"
              variant="contained"
              color="primary"
              style={{marginTop: 40, width:'60%'}}
              onClick={() => {
                setStepsEnum(stepsExchange)
                setReturnNatureCode('exchange')
                handleChangeSwipeableIndex()
              }}>
              {translate("exchange")}
            </Button>
          ) : (
            <>
            </>
          )}
          <Button
            id="return-button"
            variant="contained"
            color="primary"
            style={{marginTop: 40, width:'60%'}}
            onClick={() => {
              setStepsEnum(stepsReturn)
              setReturnNatureCode('return')
              handleChangeSwipeableIndex()
            }}>
            {translate("return_item")}
          </Button>
        </Grid>
      )
    }
  }

  return (
    <div>
      <Typography align="center" style={{fontSize:'1.2em', fontWeight: 100}}>
        {selectedItem.shopify ? selectedItem.shopify.productTitle : selectedItem.description}
      </Typography>
      <Typography align="center" style={{fontSize:'0.9em', fontWeight: 'bold'}}>
        {selectedItem.color && selectedItem.size &&
          `${selectedItem.color} / ${selectedItem.size}`
        }
      </Typography>
      <Typography align="center" style={{fontSize:'0.8em', fontWeight: 'bold', color:'#9B9B9B'}}>
        Ref : {selectedItem.externalReference}
      </Typography>
        {
          renderButtonGroup()
        }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedItem: state.returnItem.selectedItem,
    switchableProducts: state.returnItem.switchableProducts,
    selectedCountry: state.returnAdress.selectedCountry,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeSwipeableIndex: () => dispatch(handleChangeSwipeableIndex()),
    setStepsEnum: (stepsEnum) => dispatch(setStepsEnum(stepsEnum)),
    setReturnNatureCode: (returnNatureCode) => dispatch(setReturnNatureCode(returnNatureCode)),
    setDesiredItem: (desiredItem) => dispatch(setDesiredItem(desiredItem))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainStep);
